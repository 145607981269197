<template>
  <div :key="updatedAt">
    <b-row v-if="grupos.length == 0">
      <b-col>
        <h4>Por favor, selecione um ou mais grupos</h4>
      </b-col>
    </b-row>
    <app-collapse v-else>
      <div v-for="(grupo, index) in grupos" :key="grupo.id_grupo">
        <TabDistribuicaoOperador
          :vacina="vacina"
          :grupo="grupo"
          :operadores="grupo.operador ? grupo.operador : []"
          :isVisible="index == 0"
        />
      </div>
    </app-collapse>
  </div>
</template>

<script>
import { BBadge, BRow, BCol, } from "bootstrap-vue";
import {
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalGetState,
  CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import Ricon from "@/assets/images/icons/RIcon.svg";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import TabDistribuicaoOperador from "./TabDistribuicaoOperador.vue";
export default {
  components: {
    BBadge,
    BRow,
    BCol,
    TabDistribuicaoOperador,
    AppCollapse,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.setGrupos();
  },
  methods: {
    setGrupos() {
      let grupos = CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
      this.grupos = grupos != undefined ? grupos : [];
      this.updatedAt = CampanhaNacionalGetState().updatedAt;
    },

    filterGroupsHasPrice() {
       this.grupos.map(grupo => {
        grupo.operador.map(operador => {
          const precoOperador = CampanhaNacionalGetPrecificacaoDoseGestoTaxaVacinaGrupoEmpresaOperador(this.vacina.id_vacina, grupo.id_grupo, operador.id_departamento);
          if(parseFloat(precoOperador.dose_gesto_vacinal)) {
            operador.temPreco = true;
            return;
          }
          operador.temPreco = false;
        });
      });
    }
  },
  data() {
    return {
      Ricon,
      moneyMask: {
        decimal: ",",
        thousands: ".",
        precision: 2,
      },
      updatedAt: null,
      grupos: [],
    };
  },
  watch: {
    "$store.state.campanhaNacionalState.watchers.grupos": {
      immediate: false,
      handler() {
        this.setGrupos();
      },
    },
    "$store.state.campanhaNacionalState.watchers.precificacao": {
      immediate: true,
      handler() {
        this.filterGroupsHasPrice();
      },
    },
  }
};
</script>

